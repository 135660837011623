import React from 'react';

export const Divider: React.FC<{ label?: React.ReactNode | string }> = ({
  label,
}) => {
  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-slate-300 border-t" />
      </div>
      <div className="relative flex justify-center">
        <span className="bg-white px-2 text-slate-500 text-sm">{label}</span>
      </div>
    </div>
  );
};
