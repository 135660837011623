import React, { ReactElement } from 'react';
import { Link as ReactRouterLink, LinkProps } from 'react-router-dom';
import { cx } from '../../helpers/utils';

/**
 * Link wraps react rotuer's Link component and applies Tactiq's styling
 */
export function Link(
  props: LinkProps & {
    /** by default Link will inherit the current text color. Use props.blue if you want that classic blue link. */
    blue?: boolean;
    noUnderline?: boolean;
  }
): ReactElement {
  const { blue, noUnderline, className, ...linkProps } = props;
  return (
    <ReactRouterLink
      {...linkProps}
      className={cx(
        noUnderline ? '' : 'underline',
        className,
        blue ? 'text-blue-500 visited:text-purple-500' : undefined
      )}
    />
  );
}
